import React, { useState } from "react";
import { Card, Modal, TabContainer, Container, Row, Col } from "react-bootstrap";
import { useModalHandler } from "../../../helper/custom_hook";

const MonthGrid = ({ year, month, attendanceData }) => {
    function getCalendarDays(year, month) {
        const daysInMonth = new Date(year, month + 1, 0).getDate(); // Get number of days in month
        const firstDay = new Date(year, month, 1).getDay(); // Get the weekday of the 1st day of the month

        let daysArray = [];
        let week = Array(firstDay).fill(null); // Create initial array for first week, with empty slots for previous days

        // Fill in the days of the month
        for (let day = 1; day <= daysInMonth; day++) {
            week.push(day);
            if (week.length === 7) {
                daysArray.push(week); // Push the full week into the days array
                week = [];
            }
        }
        while (week.length < 7) week.push(null);
        if (week.length) daysArray.push(week);
        return daysArray;
    }

    const days = getCalendarDays(year, month);
    const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    return (
        <Container>
            <Row className="text-center font-weight-bold">
                {weekdays.map((day, idx) => (
                    <Col key={idx} className="border p-2">
                        {day}
                    </Col>
                ))}
            </Row>
            {days.map((week, weekIdx) => (
                <Row key={weekIdx} className="text-center">
                    {week.map((day, dayIdx) =>{
                        const at = attendanceData?.attendance?.find(i => {
                            const date = new Date(i.attendance_date);
                            return date.getDate() === day;
                        });
                        return  (
                        <Col key={dayIdx} className={`border p-2 ${at?.status === 'P'? 'bg-success': at?.status === 'A'?'bg-danger':at?.status==='L'?'bg-warning':''}`} style={{ width:"60px", height: "60px" }}>

                            {day !== null ? day : ""}
                        </Col>
                    )})}
                </Row>
            ))}
        </Container>
    );
};


// Usage
const AttendanceCalendar = ({ month = new Date().getMonth(), attendanceData }) => {
    const { status, toggleModal } = useModalHandler();
    const currentYear = new Date().getFullYear();
    const currentMonth = month; // 0 for January, 1 for February, etc.

    return (
        <>
            <button onClick={toggleModal} className='btn btn-sm btn-soft-info'><i className="ri-eye-fill me-2" /> View More</button>
            <Modal className="fade" size='lg' centered={true} backdrop="static" show={status} onHide={toggleModal} >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Attendance -{attendanceData?.first_name} {attendanceData?.last_name}</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MonthGrid year={currentYear} month={currentMonth} attendanceData={attendanceData} />
                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <span className="p-1 badge bg-success">Present</span>
                        <span className="p-1 badge bg-danger">Absent</span>
                        <span className="p-1 badge bg-warning">Leave</span>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AttendanceCalendar;
