import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../../components/common/BreadCrumb';
import { Card, CardBody, CardHeader, Col, Row } from 'react-bootstrap';
import CustomSelect from '../../../components/CustomSelect';
import { Link } from 'react-router-dom';
import { ASSET_URL, attendance, course, message, staff, teacher } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import { formatDate } from '../../../helper/formatDate';
import { useDispatch, useSelector } from 'react-redux';
import { setPreloader } from '../../../features/Ui/uiSlice';
import SimpleBar from 'simplebar-react';
import Swal from 'sweetalert2';

export const TeacherAttendance = () => {
    const [staffData, setStaffData] = useState([]);
    const [selectAll, setSelectAll] = useState({ present: false, absent: false });
    const [attendanceData, setAttendanceData] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        staff.list().then(r => setStaffData(r.data.users)).catch(err => swal.error(err.response ? err.response.data.message : err.message))
        const currentDate = new Date();  
        const currentMonth = currentDate.getMonth() + 1; 
        const currentYear = currentDate.getFullYear();
        const finalData = new FormData();
        finalData.append('month', currentMonth);
        finalData.append('year', currentYear)
        staff.attendanceList(finalData).then(r => setAttendanceData(r.data.attendance)).catch(err => swal.error(err.response ? err.response.data.message : err.message))
    }, []);
    const handleSaveAttendance = (e) => {
        dispatch(setPreloader({ loader: true, message: "Saving Attendance Please wait ..." }));
        e.preventDefault();
        const formData = new FormData(e.target);
        const staffAttendance = {
            attendanceData: staffData.map(student => ({
                user_id: student?.id,
                attendance: formData.get(`attendance-${student.id}`),
            })),
            attendance_date: formatDate(Date.now(), 'YYYY-MM-DD'),
        };
        staff.attendance(staffAttendance).then(res => {
            swal.success(res.message);
        }).catch(err => swal.error('Oppsss...', err.response ? err.response.data.message : err.message))
            .finally(() => dispatch(setPreloader({ loader: false, message: "" })))
    };
    const toggleSelectAll = (type) => {
        const updatedAttendance = type === 'present' ? 'P' : 'A';
        setSelectAll({ present: type === 'present', absent: type === 'absent' });
        setStaffData((prevStudents) =>
            prevStudents.map((staff) => ({
                ...staff,
                attendance: updatedAttendance,
            }))
        );
        setAttendanceData([]);
    };

    return (
        <>
            <style>
                {`
                    .form-check-input:checked.rd-success {
                        background-color: #0ab39c;
                    }
                    .form-check-input:checked.rd-danger {
                        background-color: #f06548;
                    }
                    .form-check-input:checked.rd-warning {
                        background-color: #f7b84b;
                    }
                `}
            </style>
            <BreadCrumb title="Attendance" prevPage="Home" prevPath="/dashboard" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Teacher Attendance</h5>
                            <Link to="/teacher-attendance-list" className="btn btn-soft-dark add-btn waves-effect me-2">
                                <i className="ri-contacts-fill"></i>
                            </Link>
                        </CardHeader>
                        <CardBody>
                            <div className="d-none d-lg-block">
                                <form onSubmit={handleSaveAttendance}>
                                    {(
                                        <div className="d-flex justify-content-end mb-3">

                                            <label htmlFor="markallpresent" className={`btn ${selectAll.present ? 'btn-success' : 'btn-outline-success'} ms-2`}>
                                                <input
                                                    type="checkbox"
                                                    value="markallpresent"
                                                    id="markallpresent"
                                                    className="me-2 "
                                                    checked={selectAll.present}
                                                    onChange={() => toggleSelectAll('present')}
                                                />
                                                Mark All Present
                                            </label>
                                            <label htmlFor="markallabsent" className={`btn ${selectAll.absent ? 'btn-primary' : 'btn-outline-primary'} ms-2`}>
                                                <input
                                                    type="checkbox"
                                                    value="markallabsent"
                                                    id="markallabsent"
                                                    className="me-2"
                                                    checked={selectAll.absent}
                                                    onChange={() => toggleSelectAll('absent')}
                                                />
                                                Mark All Absent
                                            </label>
                                        </div>
                                    )}
                                    <table className="table table-bordered dt-responsive nowrap table-striped align-middle">
                                        <thead>
                                            <tr>
                                                <th>SrNo.</th>
                                                <th>Name</th>
                                                <th>Contact</th>
                                                <th className="text-center">
                                                    <i className="bx bx-left-arrow-alt" />
                                                </th>
                                                <th className="text-center">{formatDate(Date.now())}</th>
                                                <th className="text-center">
                                                    <i className="bx bx-right-arrow-alt" />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {staffData.map((staff, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <img
                                                            className="me-2 rounded-circle header-profile-user"
                                                            style={{ objectFit: 'cover' }}
                                                            src={ASSET_URL + staff.avatar}
                                                            alt="User Avatar"
                                                        />
                                                        {staff.first_name} {staff.last_name}
                                                    </td>
                                                    <td>{staff.phone}</td>
                                                    <td colSpan={3}>
                                                        <div className="d-flex justify-content-around">
                                                            <input
                                                                className="form-check-input rd-success"
                                                                type="radio"
                                                                name={`attendance-${staff.id}`}
                                                                value="P"
                                                                id={`attendanceP-${staff.id}`}
                                                                checked={staff.attendance === 'P' || attendanceData?.find((att) => att.id === staff.id)?.teacher_attendance[0]?.status == 'P'}
                                                                onChange={() =>
                                                                    setStaffData(
                                                                        staffData.map((s) =>
                                                                            s.id === staff.id
                                                                                ? { ...s, attendance: 'P' }
                                                                                : s
                                                                        )
                                                                    )
                                                                }

                                                                style={{ fontSize: '20px' }}
                                                            />
                                                            <label htmlFor={`attendanceP-${staff.id}`}>
                                                                Present
                                                            </label>
                                                            <input
                                                                className="form-check-input rd-danger"
                                                                type="radio"
                                                                name={`attendance-${staff.id}`}
                                                                value="A"
                                                                checked={staff.attendance === 'A' || attendanceData?.find((att) => att.id === staff.id)?.teacher_attendance[0]?.status == 'A'}
                                                                onChange={() =>
                                                                    setStaffData(
                                                                        staffData.map((s) =>
                                                                            s.id === staff.id
                                                                                ? { ...s, attendance: 'A' }
                                                                                : s
                                                                        )
                                                                    )
                                                                }
                                                                style={{ fontSize: '20px' }}
                                                                id={`attendanceA-${staff.id}`}
                                                            />
                                                            <label htmlFor={`attendanceA-${staff.id}`}>
                                                                Absent
                                                            </label>
                                                            <input
                                                                className="form-check-input rd-warning"
                                                                type="radio"
                                                                name={`attendance-${staff.id}`}
                                                                value="L"
                                                                checked={staff.attendance === 'L' || attendanceData?.find((att) => att.id === staff.id)?.teacher_attendance[0]?.status == 'L'}
                                                                onChange={() =>
                                                                    setStaffData(
                                                                        staffData.map((s) =>
                                                                            s.id === staff.id
                                                                                ? { ...s, attendance: 'L' }
                                                                                : s
                                                                        )
                                                                    )
                                                                }
                                                                style={{ fontSize: '20px' }}
                                                                id={`attendanceO-${staff.id}`}
                                                            />
                                                            <label htmlFor={`attendanceO-${staff.id}`}>
                                                                Half-Day 
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {(
                                        <div className="d-flex align-items-start gap-3 mt-4">
                                            <button
                                                type="submit"
                                                className="btn btn-primary  right ms-auto"
                                            >

                                                Save
                                            </button>
                                        </div>
                                    )}
                                </form>
                            </div>

                            <div className="d-lg-none">
                                <div id="user_list_lst_container">
                                    <div className='row mb-3'>
                                        <div className='col'>

                                        </div>
                                    </div>
                                    <form onSubmit={handleSaveAttendance}>
                                        {(
                                            <div className="d-flex justify-content-end mb-3">

                                                <label htmlFor="markallpresent" className={`btn ${selectAll.present ? 'btn-success' : 'btn-outline-success'} ms-2`}>
                                                    <input
                                                        type="checkbox"
                                                        value="markallpresent"
                                                        id="markallpresent"
                                                        className="me-2 "
                                                        checked={selectAll.present}
                                                        onChange={() => toggleSelectAll('present')}
                                                    />
                                                    Mark All Present
                                                </label>
                                                <label htmlFor="markallabsent" className={`btn ${selectAll.absent ? 'btn-primary' : 'btn-outline-primary'} ms-2`}>
                                                    <input
                                                        type="checkbox"
                                                        value="markallabsent"
                                                        id="markallabsent"
                                                        className="me-2"
                                                        checked={selectAll.absent}
                                                        onChange={() => toggleSelectAll('absent')}
                                                    />
                                                    Mark All Absent
                                                </label>
                                            </div>
                                        )}
                                        <ul className="list list-group list-group-flush mb-0">
                                            <li className='list-group-item'>
                                                <Row>
                                                    <Col xs={6}><span className='fw-bold'>Candidate Name </span></Col>
                                                    <Col className='text-center' xs={6}>
                                                        <span className='text-wrap'>{formatDate(Date.now())} </span>
                                                    </Col>
                                                </Row>
                                            </li>
                                            <SimpleBar style={{ height: "calc(100vh - 376px)" }}>
                                                {staffData?.map((staff, index) => (
                                                    <li key={index} className="list-group-item py-3">
                                                        <Row>
                                                            <Col xs={6}><span className='fw-bold'><span> <img className="me-2 rounded-circle header-profile-user" style={{ objectFit: 'cover' }} src={ASSET_URL + staff?.avatar} alt="User Avatar" /> {staff?.first_name} {staff?.last_name}</span> </span></Col>

                                                            <Col className='d-flex align-items-center justify-content-between' xs={6}>

                                                                <div className="form-check d-flex align-items-center">
                                                                    <input
                                                                        className="form-check-input rd-success"
                                                                        type="radio"
                                                                        name={`attendance-${staff?.id}`}
                                                                        value="P"
                                                                        id={`attendanceP-${staff?.id}`}
                                                                        checked={staff.attendance === 'P' || attendanceData?.find((att) => att.id === staff.id)?.teacher_attendance[0]?.status == 'P'} onChange={() => setStaffData(staffData.map((s) => s.id === staff.id ? { ...s, attendance: 'P' } : s))} style={{ fontSize: '20px' }} />
                                                                    <label htmlFor={`attendanceP-${staff.id}`} className="form-check-label m-0 ps-3" style={{ lineHeight: '24px' }}>  P </label>
                                                                </div>
                                                                <div className="form-check d-flex align-items-center">
                                                                    <input
                                                                        className="form-check-input rd-danger"
                                                                        type="radio"
                                                                        name={`attendance-${staff.id}`}
                                                                        value="A"
                                                                        checked={staff.attendance === 'A' || attendanceData?.find((att) => att.id === staff.id)?.teacher_attendance[0]?.status == 'A'}
                                                                        onChange={() =>
                                                                            setStaffData(
                                                                                staffData.map((s) =>
                                                                                    s.id === staff.id
                                                                                        ? { ...s, attendance: 'A' }
                                                                                        : s
                                                                                )
                                                                            )
                                                                        }
                                                                        style={{ fontSize: '20px' }}
                                                                        id={`attendanceA-${staff.id}`}
                                                                    />
                                                                    <label htmlFor={`attendanceA-${staff.id}`} className="form-check-label m-0 ps-3" style={{ lineHeight: '24px' }}>
                                                                        A
                                                                    </label>
                                                                </div>
                                                                <div className="form-check d-flex align-items-center">
                                                                    <input
                                                                        className="form-check-input rd-warning"
                                                                        type="radio"
                                                                        name={`attendance-${staff.id}`}
                                                                        value="L"
                                                                        checked={staff.attendance === 'L' || attendanceData?.find((att) => att.id === staff.id)?.teacher_attendance[0]?.status == 'L'}
                                                                        onChange={() =>
                                                                            setStaffData(
                                                                                staffData.map((s) =>
                                                                                    s.id === staff.id
                                                                                        ? { ...s, attendance: 'L' }
                                                                                        : s
                                                                                )
                                                                            )
                                                                        }
                                                                        style={{ fontSize: '20px' }}
                                                                        id={`attendanceO-${staff.id}`}
                                                                    />
                                                                    <label htmlFor={`attendanceO-${staff.id}`} className="form-check-label m-0 ps-3" style={{ lineHeight: '24px' }}>
                                                                        H
                                                                    </label>
                                                                </div>

                                                            </Col>
                                                        </Row>
                                                    </li>

                                                ))}
                                            </SimpleBar>
                                        </ul>
                                        {(
                                            <div className="d-flex align-items-start gap-3 mt-4">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary btn-label right ms-auto"
                                                >
                                                    <i className="bx bxs-send label-icon align-middle fs-16 ms-2"></i>
                                                    Save
                                                </button>
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
