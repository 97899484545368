import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../../components/common/BreadCrumb';
import { Card, CardBody, CardHeader, Col, Row } from 'react-bootstrap';
import CustomSelect from '../../../components/CustomSelect';
import { Link } from 'react-router-dom';
import { ASSET_URL, attendance, course, message } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import { formatDate } from '../../../helper/formatDate';
import { useDispatch, useSelector } from 'react-redux';
import { setPreloader } from '../../../features/Ui/uiSlice';
import SimpleBar from 'simplebar-react';
import Swal from 'sweetalert2';

export const Attendance = () => {
    const [courses, setCourses] = useState([]);
    const [courseStudents, setCourseStudents] = useState([]);
    const [selectedClass, setSelectedClass] = useState(null);
    const [selectAll, setSelectAll] = useState({ present: false, absent: false });
    const [attendanceData, setAttendanceData] = useState([]);
    const authData = useSelector(state => state?.auth?._user);
    const dispatch = useDispatch();

    useEffect(() => {
        course.list()
            .then((r) => setCourses(r.data.course))
            .catch((e) => console.error(e));
    }, []);

    const handleGetCourseStudents = (e) => {
        dispatch(setPreloader({ loader: true, message: 'Fetching Students Please wait ...' }));
        setSelectedClass(e.value);
        setCourseStudents([]);
        setSelectAll({ present: false, absent: false });

        course.students(e.value)
            .then((r) => setCourseStudents(r.data.student))
            .catch((err) => swal.error('Error', err.response?.data?.message || err.message));

        const currentDate = new Date();
        const month = currentDate.getMonth() + 1;

        attendance.list_by_batch(e.value, month, currentDate.getFullYear())
            .then((res) => setAttendanceData(res.data.attendance))
            .catch((e) => swal.error(e.response?.data?.message || e.message))
            .finally(() => dispatch(setPreloader({ loader: false, message: '' })));
    };
    const handleSaveAttendance = (e) => {
        dispatch(setPreloader({ loader: true, message: "Saving Attendance Please wait ..." }));
        e.preventDefault();
        const formData = new FormData(e.target);
        const studentsAttendance = {
            attendanceData: courseStudents.map(student => ({
                user_id: student?.id,
                attendance: formData.get(`attendance-${student.id}`),
            })),
            class_id: selectedClass,
            attendance_date: formatDate(Date.now(), 'YYYY-MM-DD'),
        };
        attendance.add(studentsAttendance).then(res => {
            console.log(res)
        }).catch(err => swal.error('Oppsss...', err.response ? err.response.data.message : err.message))
            .finally(() => dispatch(setPreloader({ loader: false, message: "" })))
    };
    const toggleSelectAll = (type) => {
        const updatedAttendance = type === 'present' ? 'P' : 'A';
        setSelectAll({ present: type === 'present', absent: type === 'absent' });
        setCourseStudents((prevStudents) =>
            prevStudents.map((student) => ({
                ...student,
                attendance: updatedAttendance,
            }))
        );
        setAttendanceData([]);
    };
    const handleSendSms = (courseId) => {
        Swal.fire({
            title: "Are you sure you want to send the SMS?",
            text: " The message will be sent to the family phone number. Please ensure the family number is correct before proceeding.",
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: "Send SMS",
            denyButtonText: `cancel`
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(setPreloader({ loader: true, message: 'sending please wait ...' }))
                const formData = new FormData();
                formData.append("course_id", courseId);
                message.attendanceReminderSms(formData)
                    .then(res => {
                        swal.success(res.message);
                    })
                    .catch((err) => swal.error(err.response ? err.response.data.message : err.message))
                    .finally(() => {
                        dispatch(setPreloader({ loader: false, message: "" }));
                    });
            }
        })

    }
    return (
        <>
            <style>
                {`
                    .form-check-input:checked.rd-success {
                        background-color: #0ab39c;
                    }
                    .form-check-input:checked.rd-danger {
                        background-color: #f06548;
                    }
                    .form-check-input:checked.rd-warning {
                        background-color: #f7b84b;
                    }
                `}
            </style>
            <BreadCrumb title="Attendance" prevPage="Home" prevPath="/dashboard" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Attendance</h5>
                            <Link to="/attendance-list" className="btn btn-soft-dark add-btn waves-effect me-2">
                                <i className="ri-contacts-fill"></i>
                            </Link>
                        </CardHeader>
                        <CardBody>
                            <Row className="my-3">
                                <div className="col-12 mt-2">
                                    <label htmlFor="select_class_input" className="form-label">
                                        Select A Class
                                    </label>
                                    <CustomSelect
                                        id="select_class_input"
                                        onChange={handleGetCourseStudents}
                                        isSearchable
                                        options={courses.map((i) => ({ label: i.name, value: i.id }))}
                                    />
                                </div>
                            </Row>

                            <div className="d-none d-lg-block">
                                <form onSubmit={handleSaveAttendance}>
                                    {selectedClass && (
                                        <div className="d-flex justify-content-end mb-3">
                                            {authData.role.priority >= 60 && (
                                                <label
                                                    className="btn btn-primary btn-label right ms-auto ms-2"
                                                    onClick={() => handleSendSms(selectedClass)}
                                                >
                                                    Send SMS <i className="bx bxs-send label-icon align-middle fs-16 ms-2"></i>
                                                </label>
                                            )}

                                            <label htmlFor="markallpresent" className={`btn ${selectAll.present ? 'btn-success' : 'btn-outline-success'} ms-2`}>
                                                <input
                                                    type="checkbox"
                                                    value="markallpresent"
                                                    id="markallpresent"
                                                    className="me-2 "
                                                    checked={selectAll.present}
                                                    onChange={() => toggleSelectAll('present')}
                                                />
                                                Mark All Present
                                            </label>
                                            <label htmlFor="markallabsent" className={`btn ${selectAll.absent ? 'btn-primary' : 'btn-outline-primary'} ms-2`}>
                                                <input
                                                    type="checkbox"
                                                    value="markallabsent"
                                                    id="markallabsent"
                                                    className="me-2"
                                                    checked={selectAll.absent}
                                                    onChange={() => toggleSelectAll('absent')}
                                                />
                                                Mark All Absent
                                            </label>
                                        </div>
                                    )}


                                    <table className="table table-bordered dt-responsive nowrap table-striped align-middle">
                                        <thead>
                                            <tr>
                                                <th>SrNo.</th>
                                                <th>Name</th>
                                                <th>Contact</th>
                                                <th className="text-center">
                                                    <i className="bx bx-left-arrow-alt" />
                                                </th>
                                                <th className="text-center">{formatDate(Date.now())}</th>
                                                <th className="text-center">
                                                    <i className="bx bx-right-arrow-alt" />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {courseStudents.map((student, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <img
                                                            className="me-2 rounded-circle header-profile-user"
                                                            style={{ objectFit: 'cover' }}
                                                            src={ASSET_URL + student.avatar}
                                                            alt="User Avatar"
                                                        />
                                                        {student.first_name} {student.last_name}
                                                    </td>
                                                    <td>{student.phone}</td>
                                                    <td colSpan={3}>
                                                        <div className="d-flex justify-content-around">
                                                            <input
                                                                className="form-check-input rd-success"
                                                                type="radio"
                                                                name={`attendance-${student.id}`}
                                                                value="P"
                                                                id={`attendanceP-${student.id}`}
                                                                checked={student.attendance === 'P' || attendanceData?.find((att) => att.id === student.id)?.attendance[0]?.status == 'P'}
                                                                onChange={() =>
                                                                    setCourseStudents(
                                                                        courseStudents.map((s) =>
                                                                            s.id === student.id
                                                                                ? { ...s, attendance: 'P' }
                                                                                : s
                                                                        )
                                                                    )
                                                                }

                                                                style={{ fontSize: '20px' }}
                                                            />
                                                            <label htmlFor={`attendanceP-${student.id}`}>
                                                                Present
                                                            </label>
                                                            <input
                                                                className="form-check-input rd-danger"
                                                                type="radio"
                                                                name={`attendance-${student.id}`}
                                                                value="A"
                                                                checked={student.attendance === 'A' || attendanceData?.find((att) => att.id === student.id)?.attendance[0]?.status == 'A'}
                                                                onChange={() =>
                                                                    setCourseStudents(
                                                                        courseStudents.map((s) =>
                                                                            s.id === student.id
                                                                                ? { ...s, attendance: 'A' }
                                                                                : s
                                                                        )
                                                                    )
                                                                }
                                                                style={{ fontSize: '20px' }}
                                                                id={`attendanceA-${student.id}`}
                                                            />
                                                            <label htmlFor={`attendanceA-${student.id}`}>
                                                                Absent
                                                            </label>
                                                            <input
                                                                className="form-check-input rd-warning"
                                                                type="radio"
                                                                name={`attendance-${student.id}`}
                                                                value="L"
                                                                checked={student.attendance === 'L' || attendanceData?.find((att) => att.id === student.id)?.attendance[0]?.status == 'L'}
                                                                onChange={() =>
                                                                    setCourseStudents(
                                                                        courseStudents.map((s) =>
                                                                            s.id === student.id
                                                                                ? { ...s, attendance: 'L' }
                                                                                : s
                                                                        )
                                                                    )
                                                                }
                                                                style={{ fontSize: '20px' }}
                                                                id={`attendanceO-${student.id}`}
                                                            />
                                                            <label htmlFor={`attendanceO-${student.id}`}>
                                                                On Leave
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {selectedClass && (
                                        <div className="d-flex align-items-start gap-3 mt-4">
                                            <button
                                                type="submit"
                                                className="btn btn-primary  right ms-auto"
                                            >
                                                {/* <i className="bx bxs-send label-icon align-middle fs-16 ms-2"></i> */}
                                                Save
                                            </button>
                                        </div>
                                    )}
                                </form>
                            </div>

                            <div className="d-lg-none">
                                <div id="user_list_lst_container">
                                    <div className='row mb-3'>
                                        <div className='col'>
                                            {/* <input type="text" className='form-control' placeholder="Search..." value={searchQuery} onChange={e => setSearchQuery(e.target.value)} /> */}
                                        </div>
                                    </div>
                                    <form onSubmit={handleSaveAttendance}>
                                        {selectedClass && (
                                            <div className="d-flex justify-content-end mb-3">
                                                {authData.role.priority >= 60 && (
                                                    <label
                                                        className="btn btn-primary btn-label right ms-auto ms-2"
                                                        onClick={() => handleSendSms(selectedClass)}
                                                    >
                                                        Send SMS <i className="bx bxs-send label-icon align-middle fs-16 ms-2"></i>
                                                    </label>
                                                )}
                                                <label htmlFor="markallpresent" className={`btn ${selectAll.present ? 'btn-success' : 'btn-outline-success'} ms-2`}>
                                                    <input
                                                        type="checkbox"
                                                        value="markallpresent"
                                                        id="markallpresent"
                                                        className="me-2 "
                                                        checked={selectAll.present}
                                                        onChange={() => toggleSelectAll('present')}
                                                    />
                                                    Mark All Present
                                                </label>
                                                <label htmlFor="markallabsent" className={`btn ${selectAll.absent ? 'btn-primary' : 'btn-outline-primary'} ms-2`}>
                                                    <input
                                                        type="checkbox"
                                                        value="markallabsent"
                                                        id="markallabsent"
                                                        className="me-2"
                                                        checked={selectAll.absent}
                                                        onChange={() => toggleSelectAll('absent')}
                                                    />
                                                    Mark All Absent
                                                </label>
                                            </div>
                                        )}
                                        <ul className="list list-group list-group-flush mb-0">
                                            <li className='list-group-item'>
                                                <Row>
                                                    <Col xs={6}><span className='fw-bold'>Candidate Name </span></Col>
                                                    <Col className='text-center' xs={6}>
                                                        <span className='text-wrap'>{formatDate(Date.now())} </span>
                                                    </Col>
                                                </Row>
                                            </li>
                                            <SimpleBar style={{ height: "calc(100vh - 376px)" }}>
                                                {courseStudents?.map((student, index) => (
                                                    <li key={index} className="list-group-item py-3">
                                                        <Row>
                                                            <Col xs={6}><span className='fw-bold'><span> <img className="me-2 rounded-circle header-profile-user" style={{ objectFit: 'cover' }} src={ASSET_URL + student.avatar} alt="User Avatar" /> {student.first_name} {student.last_name}</span> </span></Col>

                                                            <Col className='d-flex align-items-center justify-content-between' xs={6}>
                                                                {/* <div className="form-check d-flex align-items-center">
                                                                    <label className="form-check-label m-0" style={{ lineHeight: '24px' }} htmlFor={`present-${student.id}`}> P </label>
                                                                    <input className="form-check-input rd-success" style={{ fontSize: '20px' }} type="radio" name={`attendance-${student.id}`} id={`present-${student.id}`} value="P" />
                                                                </div>

                                                                <div className="form-check d-flex align-items-center">
                                                                    <label className="form-check-label m-0" style={{ lineHeight: '24px' }} htmlFor={`absent-${student.id}`}> A </label>
                                                                    <input className="form-check-input rd-danger" style={{ fontSize: '24px' }} type="radio" name={`attendance-${student.id}`} id={`absent-${student.id}`} value="A" />
                                                                </div>

                                                                <div className="form-check d-flex align-items-center">
                                                                    <label className="form-check-label m-0" style={{ lineHeight: '24px' }} htmlFor={`leave-${student.id}`}> L </label>
                                                                    <input className="form-check-input rd-warning" style={{ fontSize: '24px' }} type="radio" name={`attendance-${student.id}`} id={`leave-${student.id}`} value="L" />
                                                                </div> */}
                                                                <div className="form-check d-flex align-items-center">
                                                                    <input
                                                                        className="form-check-input rd-success"
                                                                        type="radio"
                                                                        name={`attendance-${student.id}`}
                                                                        value="P"
                                                                        id={`attendanceP-${student.id}`}
                                                                        checked={student.attendance === 'P' || attendanceData?.find((att) => att.id === student.id)?.attendance[0]?.status == 'P'} onChange={() => setCourseStudents(courseStudents.map((s) => s.id === student.id ? { ...s, attendance: 'P' } : s))} style={{ fontSize: '20px' }} />
                                                                    <label htmlFor={`attendanceP-${student.id}`} className="form-check-label m-0 ps-3" style={{ lineHeight: '24px' }}>  P </label>
                                                                </div>
                                                                <div className="form-check d-flex align-items-center">
                                                                    <input
                                                                        className="form-check-input rd-danger"
                                                                        type="radio"
                                                                        name={`attendance-${student.id}`}
                                                                        value="A"
                                                                        checked={student.attendance === 'A' || attendanceData?.find((att) => att.id === student.id)?.attendance[0]?.status == 'A'}
                                                                        onChange={() =>
                                                                            setCourseStudents(
                                                                                courseStudents.map((s) =>
                                                                                    s.id === student.id
                                                                                        ? { ...s, attendance: 'A' }
                                                                                        : s
                                                                                )
                                                                            )
                                                                        }
                                                                        style={{ fontSize: '20px' }}
                                                                        id={`attendanceA-${student.id}`}
                                                                    />
                                                                    <label htmlFor={`attendanceA-${student.id}`} className="form-check-label m-0 ps-3" style={{ lineHeight: '24px' }}>
                                                                        A
                                                                    </label>
                                                                </div>
                                                                <div className="form-check d-flex align-items-center">
                                                                    <input
                                                                        className="form-check-input rd-warning"
                                                                        type="radio"
                                                                        name={`attendance-${student.id}`}
                                                                        value="L"
                                                                        checked={student.attendance === 'L' || attendanceData?.find((att) => att.id === student.id)?.attendance[0]?.status == 'L'}
                                                                        onChange={() =>
                                                                            setCourseStudents(
                                                                                courseStudents.map((s) =>
                                                                                    s.id === student.id
                                                                                        ? { ...s, attendance: 'L' }
                                                                                        : s
                                                                                )
                                                                            )
                                                                        }
                                                                        style={{ fontSize: '20px' }}
                                                                        id={`attendanceO-${student.id}`}
                                                                    />
                                                                    <label htmlFor={`attendanceO-${student.id}`} className="form-check-label m-0 ps-3" style={{ lineHeight: '24px' }}>
                                                                        L
                                                                    </label>
                                                                </div>

                                                            </Col>
                                                        </Row>
                                                    </li>

                                                ))}
                                            </SimpleBar>
                                        </ul>
                                        {selectedClass && (
                                            <div className="d-flex align-items-start gap-3 mt-4">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary btn-label right ms-auto"
                                                >
                                                    <i className="bx bxs-send label-icon align-middle fs-16 ms-2"></i>
                                                    Save
                                                </button>
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
