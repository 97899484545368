import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLayoutMode, changeSideBar } from "../../../features/Layout/layoutSlice";
import { setPreloader } from "../../../features/Ui/uiSlice";
import { authenticate } from "../../../features/Auth/authSlice";
import SimpleBar from "simplebar-react";
import Swal from "sweetalert2";
import { auth } from "../../../helper/api_url"
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

function LayoutHeader() {
  const dispatch = useDispatch()
  const ASSET_URL = useSelector(state => state.auth.ASSETURL);
  const clientData = useSelector((state) => state.auth);
  const ToggleLayoutMode = () => {
    dispatch(changeLayoutMode('toggle'));
  }
  const layoutStates = useSelector(state => state.layout);
  const handleSidebar = () => {
    if (layoutStates.screenSize === 'md') {
      if (layoutStates.sidebarSize == 'sm') dispatch(changeSideBar('lg'))
      else dispatch(changeSideBar('sm'));
    }
    if (layoutStates.screenSize === 'sm') {
      if (document.getElementsByTagName('body')[0].classList.contains('vertical-sidebar-enable'))
        document.getElementsByTagName('body')[0].classList.remove('vertical-sidebar-enable')
      else document.getElementsByTagName('body')[0].classList.add('vertical-sidebar-enable')
    }
    if (layoutStates.screenSize === 'lg') {
      if (layoutStates.sidebarSize === 'lg') dispatch(changeSideBar('sm'))
      else dispatch(changeSideBar('lg'))
    }
  }
  const authToken = useSelector(state => state.auth._token);
  const userData = useSelector(state => state.auth._user);
  const handleLogout = () => {
    dispatch(setPreloader({ loader: true, message: 'Logging Out Please Wait ....' }))
    auth.logout()
      .then(() => {
        localStorage.removeItem('_token');
        dispatch(authenticate({ _token: null, _user: {} }))
        dispatch(setPreloader({ loader: false, message: '' }))
      })
      .catch(err => {
        dispatch(setPreloader({ loader: false, message: '' }))
        Swal.fire({
          title: "error",
          text: err.response ? err.response.data.message : err.message,
          icon: "error",
          confirmButtonClass: "btn btn-primary w-xs mt-2",
          showCloseButton: !0,
        });
      });
  }
  return (
    <header id="page-topbar">
      <div className="layout-width">
        <div className="navbar-header">
          <div className="d-flex">
            <button onClick={handleSidebar} type="button" className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger" id="topnav-hamburger-icon">
              <span className={(layoutStates.screenSize === 'sm' || layoutStates.sidebarSize === 'sm') ? `hamburger-icon open` : `hamburger-icon`}>
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>
            <form className="app-search d-none d-md-none d-lg-block ">
              <div className="d-flex align-items-center ">
                <div >
                  <img src={clientData?.LOGO || "https://via.placeholder.com/100"} alt="School Logo" height={'70px'} className=" ps-" />
                </div>
                <div className=" mx-5">
                  <h1 className="academy-title align-items-center ps-3">
                    {clientData?.APPNAME.split(" ").map((part, index, arr) => (<span key={index} className={index === arr.length - 1 ? "text-danger fs-1" : "text-primary fs-1"}>{part.toUpperCase()}{" "}</span>))}
                  </h1>
                </div>
              </div>
            </form>
          </div>
          <h1 className="academy-titl d-lg-none  d-flex align-items-center justify-content-between mt-2">
            <div className="">
              <img src={clientData?.LOGO || "https://via.placeholder.com/100"} alt="School Logo" height={'50px'} className="d-md-none d-none ps-2" />
            </div>
            {clientData?.APPNAME.split(" ").map((part, index, arr) => (<span key={index} className={index === arr.length - 1 ? "text-danger fs-1" : "text-primary fs-1"}>{part.toUpperCase()}{" "}</span>))}
          </h1>
          <div className="d-flex align-items-center">
            <div className="ms-1 header-item d-none d-sm-flex">
              <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" data-toggle="fullscreen" >
                <i className="bx bx-fullscreen fs-22"></i>
              </button>
            </div>
            <div className="ms-1 header-item d-sm-flex">
              <button onClick={ToggleLayoutMode} type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode">
                <i className="bx bx-moon fs-22"></i>
              </button>
            </div>
            <div className="dropdown ms-sm-3 header-item topbar-user">
              <button type="button" className="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="d-flex align-items-center">
                  <img className="rounded-circle header-profile-user" src={`${ASSET_URL}${userData.avatar}`} alt="Header Avatar" />
                  <span className="text-start ms-xl-2">
                    <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                      {userData.first_name} {" "} {userData.last_name}
                    </span>
                    <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                      {userData.role.name}
                    </span>
                  </span>
                </span>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <h6 className="dropdown-header">Welcome {userData.first_name}!</h6>
                <Link className="dropdown-item" to="/profile">
                  <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                  <span className="align-middle">Profile</span>
                </Link>
                <a className="dropdown-item" href="pages-faqs.html">
                  <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>
                  <span className="align-middle">Help</span>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="pages-profile-settings.html">
                  <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>
                  <span className="align-middle">Settings</span>
                </a>
                <button onClick={handleLogout} className="dropdown-item">
                  <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                  <span className="align-middle" data-key="t-logout">
                    Logout
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default LayoutHeader;
