import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Card, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import PieChart from '../../../helper/Charts/PieChart';
import { ASSET_URL, dashboard } from '../../../helper/api_url';
import CustomSelect from '../../../components/CustomSelect';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { TableResponsive } from '../../../components/common/TableResponsive';
import { handleViewInvoice } from '../../../helper/view-invoice';
import { Bar,  Line, PolarArea } from 'react-chartjs-2';
import userDefault from '../../../assets/images/users/user-dummy-img.jpg'
import './dashboard.css';
import { authenticatedRoutes } from '../../../routes';
function Index() {
    const AuthUser = useSelector(state => state.auth);
    const userData = useSelector(state => state.auth._user);
    const roleType = useSelector(state => state.auth._roleType);
    const [dashboardData, setDashboardData] = useState([]);
    const [recentTransactions, setRecentTransactions] = useState([]);
    const [duration, setDuration] = useState('daily');
    const [datas, setDatas] = useState({ labels: [], datasets: [] });
    const [mydata, setMonthlyFees] = useState({ labels: [], datasets: [] });
    const months = ["December", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November",];
    let year = 2024;
    const formattedMonths = months.map((month, index) => {
        if (month === "January") year++;
        const monthNumber = (index === 0 ? 12 : index).toString().padStart(2, '0');
        return `${year}-${monthNumber}`;
    });
    const chartOptions = {
        plugins: {
            legend: { labels: { font: { size: 10, weight: "bold", }, color: "#333", usePointStyle: false, boxWidth: 0, }, },
            tooltip: { callbacks: { label: function (context) { let value = context.raw || 0; let datasetLabel = context.dataset.label || ""; return `${datasetLabel}: ${value} ₹`; }, }, },
        },
        scales: {
            y: { ticks: { callback: function (value) { return `₹ ${value}` }, font: { size: 12, weight: "bold" }, color: "#555", }, grid: { color: "rgba(0,0,0,0.1)" }, },
            x: { ticks: { font: { size: 12, weight: "bold" }, color: "#555", }, grid: { display: false }, },
        },

    };

    useEffect(() => {
        if (!dashboardData) return;
        const summary = dashboardData?.attendanceData?.monthly_summary || {};
        const monthlyFeesInfo = dashboardData?.feesData?.monthly_dues || [];
        const monthlyExpense = dashboardData?.feesData?.monthly_expenses || [];
        const monthlyCollection = dashboardData?.feesData?.monthly_fees_payments || [];
        const totalDues = monthlyFeesInfo.reduce((acc, { month, total_dues }) => ({ ...acc, [month]: total_dues || 0 }), {});
        const totalExpenses = monthlyExpense.reduce((acc, { month, total_expenses }) => ({ ...acc, [month]: total_expenses || 0 }), {});
        const totalCollection = monthlyCollection.reduce((acc, { month, total_collections }) => ({ ...acc, [month]: total_collections || 0 }), {});
        const getMonthlyDues = () => formattedMonths.map((month) => totalDues[month] || 0);
        const getMonthlyExpenses = () => formattedMonths.map((month) => totalExpenses[month] || 0);
        const getMonthlyCollection = () => formattedMonths.map((month) => totalCollection[month] || 0);
        const getMonthlyProfit = () => { return getMonthlyCollection().map((collection, index) => collection - getMonthlyExpenses()[index]); };
        const monthlyFeesData = {
            labels: months.map((month) => month.slice(0, 3)),
            datasets: [
                { label: "🔴 Total Dues", data: getMonthlyDues(), borderColor: "rgb(240, 101, 72)", fill: false, tension: 0.1 },
                { label: "🔵 Collection", data: getMonthlyCollection(), borderColor: "rgb(41, 156, 219)", fill: false, tension: 0.1 },
                { label: "🟡 Expense", data: getMonthlyExpenses(), borderColor: "rgb(247, 184, 75)", fill: false, tension: 0.1 },
                { label: "🟢 Profit", data: getMonthlyProfit(), borderColor: "rgb(10, 179, 156)", fill: false, tension: 0.1 },
            ]
        }
        const getMonthlyData = (type) => months.map((month) => summary[month]?.[type] || 0);
        const attendanceData = {
            labels: months.map((month) => month.slice(0, 3)),
            datasets: [
                { label: "Present", backgroundColor: "rgb(10, 179, 156)", data: getMonthlyData("present"), },
                { label: "Absent", backgroundColor: "rgb(240, 101, 72)", data: getMonthlyData("absent"), },
                { label: "Leave", backgroundColor: "rgb(247, 184, 75)", data: getMonthlyData("leave"), },
                { label: "Unmarked", backgroundColor: "rgb(31, 32, 33)", data: getMonthlyData("unmarked"), },
            ],
        };
        setMonthlyFees(monthlyFeesData);
        setDatas(attendanceData);
    }, [dashboardData]);
    const TotalUser = {
        labels: ['Home Work', 'Class'],
        datasets: [{
            label: 'No.',
            data: [dashboardData?.homeworkData?.total_homework, dashboardData?.classData?.total_class,],
            backgroundColor: [
                'rgb(255, 99, 132)',
                "rgb(10, 179, 156)",
                'rgb(255, 205, 86)',
                'rgb(33, 37, 41)',
                'rgb(54, 162, 235)'
            ]
        }]
    };
    useEffect(() => {
        dashboard.data().then(res => {
            setDashboardData(res.data)
            setRecentTransactions(res.data?.feesData?.recent_transactions);
        }).catch(e => console.log(e));
    }, []);

    const columns = useMemo(() => [
        {
            Header: "Name",
            Cell: (cell) => {
                const imageUrl = ASSET_URL + cell.row.original.payer?.avatar;
                return (<span>
                    <img className="me-2 rounded-circle header-profile-user" style={{ objectFit: 'cover' }} src={cell.row.original.payer?.avatar ? imageUrl : userDefault} alt="User Avatar" />
                    {cell.row.original.payer_name}
                </span>)
            },
        },
        {
            Header: "Details",
            Cell: cell => {
                const row = cell.row.original;
                return <div className='d-flex justify-content-between'>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{row?.payment_mode}</Tooltip>}
                    >
                        <span className='cursor-pointer'>
                            {row?.total_amount}{" "}
                            {row?.payment_mode === "cash" ? (
                                <i className="mdi mdi-cash fs-4 text-info" />
                            ) : row?.payment_mode === "upi" ? (
                                <i className="bx bxl-paypal fs-4 text-primary" />
                            ) : row?.payment_mode === "online" ? (
                                <i className="mdi mdi-remote fs-4 text-warning" />
                            ) : (
                                <i className="bx bx-rupee fs-4 text-danger" />
                            )}
                        </span>
                    </OverlayTrigger>
                    <Button onClick={(e) => handleViewInvoice(row.id)} className="btn btn-sm btn-soft-info me-1">
                        <i className="ri-eye-fill" />
                    </Button>
                </div>
            }
        },
        {
            Header: 'List',
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: row => {
                const imageUrl = ASSET_URL + row.payer?.avatar;
                return <div className='d-flex justify-content-between'>
                    <div className='d-flex align-items-center gap-4'>
                        <span><img className="me-2 rounded-circle header-profile-user" style={{ objectFit: 'cover' }} src={imageUrl} alt="User Avatar" />
                            {row.payer_name}</span>
                        <span className='text-muted'>{row.total_amount} ({row.payment_mode})</span>
                    </div>
                    <Button onClick={(e) => handleViewInvoice(row.id)} className="btn btn-sm btn-soft-info me-1">
                        <i className="ri-eye-fill" />
                    </Button>
                </div>
            }
        }

    ], [])
    const getFees = () => {
        return duration == 'daily'
            ? dashboardData?.feesData?.fees_payment_today
            : (duration == 'monthly' ? dashboardData?.feesData?.fees_payment_month : dashboardData?.feesData?.fees_payment_year)
    }
    const getExpense = () => {
        return duration == 'daily'
            ? dashboardData?.feesData?.expense_today
            : (duration == 'monthly' ? dashboardData?.feesData?.expense_month : dashboardData?.feesData?.expense_year)
    }
    const colors = ["primary", "danger", "success", "warning", "info", "secondary", "dark"];
    const financeData = [
        { label: 'Total Dues', value: dashboardData?.feesData?.dues, icon: 'ri-bank-fill', bg: 'danger' },
        { label: 'Collection', value: getFees(), icon: 'ri-wallet-3-fill', bg: 'info' },
        { label: 'Expense', value: getExpense(), icon: 'bx bx-dollar-circle', bg: 'warning' },
        { label: 'Profit', value: parseFloat(getFees() || 0) - parseFloat(getExpense() || 0), icon: 'bx bxs-dollar-circle', bg: 'success' }
    ];

    const demandBills = [
        { label: 'Family Demand Bill', path: '/family-demand-bill', icon: 'mdi mdi-hand-heart-outline', bg: 'danger' },
        { label: 'Class Demand Bill', path: '/class-demand-bill', icon: 'mdi mdi-google-classroom', bg: 'info' },
        { label: 'Family Dues', path: '/family-dues', icon: 'mdi mdi-account-cash', bg: 'warning' },
        { label: 'Invoice', path: '/invoice-list', icon: 'bx bx-rupee', bg: 'black' }
    ];
    const attendanceStats = [
        { label: "Total Students", value: dashboardData?.userData?.no_of_students, icon: "ri-team-line", color: "danger" },
        { label: "Present", value: dashboardData?.attendanceData?.no_of_present_students, icon: "ri-user-follow-fill", color: "info" },
        { label: "On Leave", value: dashboardData?.attendanceData?.no_of_leave_students, icon: "ri-arrow-up-circle-line", color: "warning" },
        { label: "Unmarked", value: dashboardData?.attendanceData?.no_of_unmarked_students, icon: "ri-question-fill", color: "black" }
    ];
    return (
        <>
            <div className="main-content-page">
                <h4 className='text-primary fw-bold px-2 mb-0 mt-2'>{userData?.role?.name} Dashboard</h4>
                <div className='header-border'></div>
                <Row className='d-flex justify-content-center mx-2 my-4'>
                    {authenticatedRoutes
                        .filter(route => route.users.includes(roleType) && route.dbtype === 'menu' && route.label !== 'Dashboard')
                        .map((route, idx) => (
                            <Col lg={2} md={3} sm={4} xs={6} key={idx} className='p-2 text-center'>
                                <OverlayTrigger placement='top' overlay={<Tooltip id={`tooltip-${idx}`} className='fs-6'>{route.dblabel}</Tooltip>}>
                                    <Link to={route.path} className='text-decoration-none'>
                                        <div className={`p-3 rounded bg-${colors[idx % colors.length]} text-light d-flex flex-column align-items-center justify-content-center shadow-sm`} style={{ height: '50px', width: '100%' }}>
                                            <i className={`${route.icon} fs-1`}></i>
                                        </div>
                                    </Link>
                                </OverlayTrigger>
                            </Col>
                        ))}
                </Row>
                <div className='mx-3 mb-3'>
                    {AuthUser?._user?.role?.priority >= 80 && (
                        <Row className='overflow-hidden d-flex mt-4'>
                            <Col lg={6} md={12} sm={12} xs={12}>
                                <div className='d-flex justify-content-between'>
                                    <h3 className=''>Fees Information</ h3>
                                    <div className="" style={{ width: '200px' }}>
                                        <CustomSelect defaultValue={{ label: 'Daily', value: 'daily' }} onChange={e => setDuration(e.value)} options={[
                                            { label: 'Daily', value: 'daily' },
                                            { label: 'Monthly', value: 'monthly' },
                                            { label: 'Yearly', value: 'yearly' },
                                        ]} />
                                    </div>
                                </div>
                                <Row className='mt-3'>
                                    <Col lg={3} md={3} sm={4} xs={12} className="mb-4">
                                        <Row className='d-flex justify-content-between'>
                                            {financeData.map((item, idx) => (
                                                <Col key={idx} lg={12} md={12} sm={12} xs={6} className='mt-2'>
                                                    <Link to="/dues-list" className='my-2'>
                                                        <div className={`py-2 px-3 serviceBox bg-${item.bg} rounded position-relative d-flex flex-column align-items-center justify-content-center text-center`}>
                                                            <h2 className="text-uppercase fs-5 text-white font-monospace" style={{ textShadow: "2px 2px 8px rgba(255, 255, 255, 0.9)" }}>{item.label}</h2>
                                                            <div className="position-absolute top-50 start-50 pt-3 translate-middle icon-transparent">
                                                                <i className={`${item.icon} display-6 text-white d-block`}></i>
                                                            </div>
                                                            <h5 className="mb-0 text-white">
                                                                <span className="counter-value font-monospace">{item.value}</span>
                                                            </h5>
                                                        </div>
                                                    </Link>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                    <Col lg={9} md={9} sm={8} xs={12} className="mb-4">
                                        <Card className='pb-2 rounded' >
                                            <Card.Header className='m-0 fs-5 pb-2 font-monospace'>Monthly Income & Expense Overview</Card.Header>
                                            <div className="d-flex justify-content-center px-1" >
                                                <Line data={mydata} options={chartOptions} />
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={4} md={8} sm={12} xs={12}>
                                {AuthUser?._user?.role?.priority >= 80 && (
                                    <Row className='flex'>
                                        <Col >
                                            <Card >
                                                <Card.Header className='m-0'>
                                                    <h5>Recent Transactions</h5>
                                                </Card.Header>
                                                <TableResponsive
                                                    customPageSize={3}
                                                    showFilter={false}
                                                    isPagination={true}
                                                    isShowingPageLength={false}
                                                    showCustomOptionPage={false}
                                                    columns={columns}
                                                    data={recentTransactions}
                                                    responsiveDataManage={true}
                                                    tableClass='table table-border table-hover table-centered table-nowrap'
                                                />
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                            <Col lg={2} md={4} sm={12} xs={12} className='px-0'>
                                <Card.Header className='text-center'>
                                    <h5>Demand Bill</h5>
                                </Card.Header>
                                <Row className='mx-2'>
                                    {demandBills.map((item, idx) => (
                                        <Col key={idx} lg={12} xs={6} md={12} className='mt-2'>
                                            <Link to={item.path} className=''>
                                                <div className={`py-2 px-2 serviceBox bg-${item.bg}  rounded position-relative d-flex flex-column align-items-center justify-content-center text-center`}>
                                                    <h2 className="text-uppercase fs-6 text-white font-monospace" style={{ textShadow: "2px 2px 8px rgba(255, 255, 255, 0.9)" }}>{item.label}</h2>
                                                    <div className="position-absolute top-50 start-50 pt-3 translate-middle icon-transparent">
                                                        <i className={`${item.icon} display-6 text-white d-block`}></i>
                                                    </div>
                                                    <h5 className="mb-0 text-white">
                                                        <span className="counter-value font-monospace">0</span>
                                                    </h5>
                                                </div>
                                            </Link>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                    )}
                </div>
            </div>
            <hr className='border border-dotted border-1 border-dark m-0' />
            <div className='mx-3'>
                {AuthUser?._user?.role?.priority >= 60 && (
                    <Row className="overflow-hidden d-flex mt-2">
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <div className='d-flex justify-content-between'>
                                <h3>Attendance Information</ h3>
                                <div className="" style={{ width: '200px' }}>
                                    <CustomSelect defaultValue={{ label: 'Today', value: 'today' }} options={[
                                        { label: 'Today', value: 'today' },
                                        { label: 'Yesterday', value: 'yesterday' },
                                        { label: '2 days ago', value: '2dayago' },
                                    ]} />
                                </div>
                            </div>
                            <Row className='mt-3'>
                                <Col lg={3} md={3} sm={4} xs={12} className="mb-4">
                                    <Row>
                                        {attendanceStats.map((stat, idx) => (
                                            <Col key={idx} lg={12} md={12} sm={12} xs={6} className="mt-2">
                                                <Link to="/attendance">
                                                    <div className={`py-2 px-3 serviceBox bg-${stat.color} rounded d-flex flex-column align-items-center justify-content-center text-center position-relative`}>
                                                        <h5 className="text-uppercase fs-6 text-white font-monospace" style={{ textShadow: "2px 2px 8px rgba(255, 255, 255, 0.9)" }}>
                                                            {stat.label}
                                                        </h5>
                                                        <div className="position-absolute top-50 start-50 pt-3 translate-middle icon-transparent">
                                                            <i className={`${stat.icon} display-6 text-white`}></i>
                                                        </div>
                                                        <h5 className="mb-0 text-white font-monospace">
                                                            <span className="counter-value">{stat.value}</span>
                                                        </h5>
                                                    </div>
                                                </Link>
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                                <Col lg={9} md={9} sm={8} xs={12} className="mb-4">
                                    <Card className='pb-2 rounded' >
                                        <Card.Header className='m-0 fs-5 pb-2 font-monospace'>Attendance</Card.Header>
                                        <div className="d-flex justify-content-center px-1" >
                                            <Bar data={datas} options={{ responsive: true }} />
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <Row className=''>
                                <Col lg={8} md={8} sm={8} xs={12} className="mb-4">
                                    <Card className='pb-2 rounded'>
                                        <Card.Header className='m-0 fs-5 pb-2 font-monospace'>Works & Class</Card.Header>
                                        <div className="d-flex justify-content-center px-1" >
                                            <PolarArea
                                                data={TotalUser}
                                                options={{
                                                    responsive: true,
                                                    maintainAspectRatio: false,
                                                    aspectRatio: 1.5,
                                                }}
                                                style={{ height: '280px' }}
                                            />
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={4} md={4} sm={4} xs={12} className='px-0'>
                                    <Row className='d-flex mb-4'>
                                        <Col>
                                            <Card.Header className='text-center'>
                                                <h5>Total Users</h5>
                                            </Card.Header>
                                            <div className='d-flex flex-wrap justify-content-center'>
                                                {[
                                                    { label: 'Admin', count: dashboardData?.userData?.no_of_admins || 0 },
                                                    { label: 'Staffs', count: dashboardData?.userData?.no_of_staffs || 0 },
                                                    { label: 'Students', count: dashboardData?.userData?.no_of_students || 0 }
                                                ].map((user, index) => (
                                                    <div key={index} className='cursor-pointer m-2'>
                                                        <div className="user-card bg-light text-center p-3">
                                                            <div className="icon-container">
                                                                <i className="ri-user-fill icon"></i>
                                                            </div>
                                                            <h5 className="user-label text-dark mt-2">{user.label}</h5>
                                                            <span className="user-count">{user.count}</span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
            </div>
        </>
    )
}

export default Index