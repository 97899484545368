import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import CustomSelect from '../../../../components/CustomSelect'
import { useModalHandler, useWebCam } from '../../../../helper/custom_hook';
import dummyUser from "../../../../assets/images/users/user-dummy-img.jpg";
import Camera from '../../../../components/common/Camera';
import { Col, Row } from 'react-bootstrap';
import BreadCrumb from '../../../../components/common/BreadCrumb';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { swal } from '../../../../helper/swal';
import { student } from '../../../../helper/api_url';

export const CreateAdmission = () => {
    const { genders, status, toggleModal, userProfile, setUserProfile } = useModalHandler();
    const { webcam, toggleWebcam, setCapturedImageToInput } = useWebCam(setUserProfile);
    const onSave = (f) => setCapturedImageToInput(f, document.getElementById("avatarInput"));
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const mutation = useMutation({
		mutationFn: (formData) => student.add(formData),
		onSuccess: (res) => {
			queryClient.setQueryData(['students'], (oldData) => {
				if (!oldData) return oldData;
				return {...oldData,data: {...oldData.data,student: [res.data.student, ...oldData.data.student]}};});
            const studentId=res?.data?.student?.id;
            navigate('/student-admission-profile/'+studentId);
		},
		onError: (err) => {swal.error(err.response ? err.response.data.message : err.message);}
	});
    const handleAdmission =(e)=>{
        e.preventDefault();
        const formData = new FormData(e.target);
		mutation.mutate(formData);
    }
    return (
        <>
            <BreadCrumb title="Addmission" prevPage="Home" prevPath="/admission" />
            <div className="row justify-content-center">
                <div className="col-md-8 col-lg-6 col-xl-5">
                    <div className="card mt-4">
                        <form onSubmit={handleAdmission}>
                            <div className="card-body p-4">
                                <Row>
                                    <Col className="text-center">
                                        <div className="profile-user position-relative d-inline-block mx-auto mb-2">
                                            <label htmlFor="profile-img-file-input" className="cursor-pointer" >
                                                <img src={userProfile ? userProfile : dummyUser} htmlFor="profile-img-file-input" className="rounded-circle avatar-lg img-thumbnail user-profile-image cursor" alt="" />
                                            </label>
                                            <div className="avatar-xs ms-0 me-5 mb-2 rounded-circle profile-photo-edit" style={{ marginLeft: "31px" }} >
                                                <input id="profile-img-file-input" type="file" name="avatar" className="profile-img-file-input" accept="image/png, image/jpeg, image/jpg" onChange={(e) => onSave(e.target.files[0])} defaultValue="" />
                                            </div>
                                            <div className="avatar-xs rounded-circle profile-photo-edit">
                                                <div onClick={toggleWebcam} className="avatar-title rounded-circle bg-light text-body"  > <i className="ri-camera-fill"></i>  </div>
                                            </div>
                                        </div>
                                        {webcam ? (<div className="position-relative top-0 bottom-0 d-flex justify-content-center align-items-center start-0 end-0 modal-content border-0" style={{ zIndex: 9 }} ><Camera onSave={(f) => { onSave(f); toggleWebcam(); }} onClose={() => toggleWebcam()} /></div>) : null}
                                    </Col>
                                </Row>
                                <div className="p-2">

                                    <div className='row'>
                                        <div className="col-lg-6 col-xs-12">
                                            <div className="mb-3">
                                                <label htmlFor="first_name" className="form-label"> First Name <span className='text-danger'>*</span> </label>
                                                <input type="text" className="form-control" name="first_name" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xs-12">
                                            <div className="mb-3">
                                                <label htmlFor="last_name" className="form-label"> Last Name </label>
                                                <input type="text" className="form-control" name="last_name" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xs-12">
                                            <div className="mb-3">
                                                <label htmlFor="gender" className="form-label"> Gender <span className='text-danger'>*</span> </label>
                                                <CustomSelect name="gender" id="gender" isSearchable options={genders} />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xs-12">
                                            <div className="mb-3">
                                                <label htmlFor="dob" className="form-label"> Date of Birth</label>
                                                <input type="date" className="form-control" name="dob" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="mb-3">
                                                <label htmlFor="email" className="form-label"> Email  </label>
                                                <input type="email" className="form-control" name="email" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="mb-3">
                                                <label htmlFor="phone" className="form-label"> Phone <span className='text-danger'>*</span> </label>
                                                <input type="text" className="form-control" name="phone" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="mb-3">
                                                <label htmlFor="family_phone" className="form-label"> Family Phone <span className='text-danger'>*</span> </label>
                                                <input type="text" className="form-control" name="family_phone" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="mb-3">
                                                <label htmlFor="fatherName" className="form-label"> Father Name <span className='text-danger'>*</span> </label>
                                                <input type="text" className="form-control" name="father_name" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="mb-3">
                                                <label htmlFor="mothername" className="form-label"> mother Name <span className='text-danger'>*</span> </label>
                                                <input type="text" className="form-control" name="mother_name" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <button className="btn btn-success w-100" type="submit">Submit </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </>
    )
}
